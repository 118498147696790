import axios from 'axios';

const url = process.env.VUE_APP_ROOT_API ;
const ccPortal = 'http://localhost:6601';
const opayApi  = 'http://localhost:6502';

export default {
  checkIps(userName) {
    return axios.get(url+'/aacore/user/portal/validate-ip/'+userName, {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin' : '*'
      }
    });
  },

  login(credentials) {
    return axios.post(url + '/aacore/login', credentials, {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin' : '*'
        }
  }).then(response => response.headers);
      //.then(response => console.info("headers:", response.headers));
  },

  logout() {
    return axios.post(url + '/aacore/user/logout').then(response => response.data);
  },

  checkLogin(credentials) {
    return axios.post(url + '/aacore/login', credentials).then(response => response).catch((error) => { return error.response });
  },

  sentOtpToken(){
    return axios.post(url + '/aacore/user/email-otp').then(response => response.data).catch((error) => { return error.response });
  },

  validateOtpToken(params){
    return axios.post(url + '/aacore/user/email-otp/validate',params).then(response =>response.data).catch((error) => { return error.response });
  },

  changePasswd(params){
     return axios.post(url + '/aacore/user/change/password/' + params.oldPasswd +'/'+ params.newPasswd, params, {
         headers: {
           'Content-Type': 'application/json',
           'Access-Control-Allow-Origin' : '*'
         }
     }).then(response => response.data)
     .catch((error) => { return error.response });
  },

  replacePasswd(params){
     return axios.get(url + '/aacore/user/change/password/' + params.userName +'/'+ params.newPasswd).then(response => response.data)
     .catch((error) => { return error.response });
  },

  replacePasswdv2(params){
     return axios.post(url + '/aacore/user/change/admin-password', params, {
         headers: {
             'Content-Type': 'application/json',
             'Access-Control-Allow-Origin' : '*'
         }
     }).then(response => response.data)
     .catch((error) => { return error.response });
  },

  getUserList(params){
    return axios.post(url + '/customer-care-portal/user/list/all/'+params.page+'/' + params.size).then(response => response.data).catch((error) => { return error.response });
  },

  getUserListV2(params){
    return axios.post(url + '/customer-care-portal/user/list/all/'+params.page+'/' + params.size, params.title).then(response => response.data).catch((error) => { return error.response });
  },

  getUserDetail(id){
    return axios.get(url + '/customer-care-portal/user/userIdentify/'+ id ).then(response => response.data.payload).catch((error) => { return error.response });
  },

  getUsrDtlByPhone(phoneNum){
    return axios.get(url + '/customer-care-portal/user/get/'+ phoneNum ).then(response => (response.hasOwnProperty('data') && response.data.hasOwnProperty('payload') ? response.data.payload : response)).catch((error) => { return error.response });
  },

  getKYCImages(id){
    return axios.get(url + '/customer-care-portal/user/images/kyc/'+ id ).then(response => response.data.payload).catch((error) => { return error.response });
  },

  genOtpForActivateUser(){
    return axios.get(url + '/opay-api/user/admin/activate-user/generate-otp').then(response => response.data).catch((error) => { return error.response });
  },

  validateUserByOTP(params){ //console.log(params);
    return axios.post(url + '/opay-api/user/admin/activate',params ).then(response => response.data)
    .catch((error) => { return error.response });
  },

  updateStsOpayUser(id, status, message=''){ //console.log(params);
    return axios.post(url + '/opay-api/user/admin/'+ status + '/' + id + '/' + message, null, {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin' : '*'
      }
    }).then(response => response.data)
    .catch((error) => { return error.response });
  },

  updateStsAuthUser(userName, status){ //console.log(params);
    return axios.get(url + '/aacore/admin/management/change/state/'+ status + '/' + userName ).then(response => response.data)
    .catch((error) => { return error.response });
  },

  updateUserStatus(id, params){ //console.log(params);
      return axios.post(url + '/customer-care-portal/user/update/'+ id, params ).then(response => response.data).catch((error) => { return error.response });
  },

  getUserTransact(params){ //console.log(params);
    //return axios.post(ccPortal + '/transaction/list/0/1000',params).then(response => response.data.payload)
    return axios.post(url + '/customer-care-portal/transaction/list/0/1000',params).then(response => response.data.payload)
    .catch((error) => { return error.response });
  },

  getUserPoints(params){ //console.log(params);
    return axios.get(url + '/customer-care-portal/user/m-coins/'+ params.id +'/'+params.status).then(response => response.data.payload).catch((error) => { return error.response });
  },

  cancelUserPoint(id){ //console.log(id);
    return axios.get(url + '/customer-care-portal/transaction/cancel-mcoins/'+ id).then(response => response.data.payload)
    .catch((error) => { return error.response });
  },

  ReseteWalletPin(phoneNum){ //console.log(id);
    return axios.get(url + '/aacore/user/update/pin/request/'+ phoneNum ).then(response => response.data).catch((error) => { return error.response });
  },

  sendResetPinNotification(userId){
    return axios.get(url + '/opay-api/admin/notification/password-reset/'+ userId).then(response =>response.data)
    .catch((error) => { return error.response });
  },

  getConfigList(){
     return axios.get(url + '/customer-care-portal/config/list', {
         headers: {
           'Content-Type': 'application/json',
           'Access-Control-Allow-Origin' : '*'
         }
     }).then(response => response.data.payload).catch((error) => { return error.response });
  },

  getSystemConfigList(){
     return axios.get(url + '/customer-care-portal/config/system/list/0/1000').then(response => response.data.payload)
     .catch((error) => { return error.response });
  },

  updateSystemConfig(params){ //console.log(params);
    return axios.post(url + '/customer-care-portal/config/system/update',params).then(response =>response.data)
    .catch((error) => { return error.response });
  },

  creditUser(params){
    return axios.post(url + '/customer-care-portal/transaction/system/adjustment/debit',params).then(response =>response.data)
    .catch((error) => { return error.response });
  },

  debitUser(params){
    return axios.post(url + '/customer-care-portal/transaction/system/adjustment/credit',params).then(response =>response.data)
    .catch((error) => { return error.response });
  },

  debitCreditValidate(debitUser){
    return axios.post(url + '/customer-care-portal/transaction/system/adjustment/validate/'+debitUser).then(response =>response.data)
    .catch((error) => { return error.response });
  },

  getWalletGroupList(){
    //return axios.get(opayApi + '/user/wallet/view-groups').then(response => response.data.payload)
    return axios.get(url + '/opay-api/user/wallet/view-groups').then(response => response.data.payload)
    .catch((error) => { return error.response });
  },

  getWalletGroup(walletGroupId){
    //return axios.get(opayApi + '/user/wallet/' + walletGroupId).then(response => response.data.payload)
    return axios.get(url + '/opay-api/user/wallet/' + walletGroupId).then(response => response.data.payload)
    .catch((error) => { return error.response });
  },

  createWalletGroup(groupName,follusergroup,params){ //console.log(params);
    //return axios.post(opayApi + '/user/wallet-group/add/'+ groupName +'/'+ follusergroup, params).then(response =>response.data)
    return axios.post(url + '/opay-api/user/wallet-group/add/'+ groupName +'/'+ follusergroup, params).then(response =>response.data)
    .catch((error) => { return error.response });
  },

  updateWalletGroup(walletGroupId,follusergroup,params){ //console.log(params);
    //return axios.post(opayApi + '/user/wallet-group/update/'+ walletGroupId +'/'+ follusergroup, params).then(response =>response.data)
    return axios.post(url + '/opay-api/user/wallet-group/update/'+ walletGroupId +'/'+ follusergroup, params).then(response =>response.data)
    .catch((error) => { return error.response });
  },

  addWalletUser(params){ //console.log(params);
    return axios.post(url + '/opay-api/user/wallet/add/'+ params.userId +'/'+ params.groupId).then(response =>response.data).catch((error) => { return error.response });
  },

  getWalletLimit(userId){ //console.log(params);
    return axios.get(url + '/opay-api/user/get-wallet-limit/'+ userId).then(response =>response.data).catch((error) => { return error.response });
  },

  getListLastLoginUsers(params){  //console.log(params);
    return axios.post(url + '/customer-care-portal/report/last-login-users/'+params.fromDate+'/' + params.toDate).then(response => response.data)
    .catch((error) => { return error.response });
  },

  getUserDeactReqList(){
    //return axios.get(opayApi + '/user/wallet/view-groups').then(response => response.data.payload)
    return axios.get(url + '/opay-api/user/deactivate/list').then(response => response.data.payload)
    .catch((error) => { return error.response });
  },

  DeactivateAuthUser(username){ //console.log(params);
    return axios.get(url + '/aacore/admin/management/change/state/deactivate/'+ username).then(response =>response.data)
    .catch((error) => { return error.response });
  },

  DeactivateOpayUser(id){ //console.log(params);
    return axios.get(url + '/opay-api/user/deactivate/complete/request/'+ id).then(response =>response.data)
    .catch((error) => { return error.response });
  },

  cancelDeactivateUser(id){ //console.log(params);
    return axios.get(url + '/opay-api/user/deactivate/cancel/request/'+ id).then(response =>response.data)
    .catch((error) => { return error.response });
  },

  updateWalletUser(userIdentify,params){
    return axios.post(url + '/customer-care-portal/user/update/'+ userIdentify,params).then(response => response.data)
    .catch((error) => { return error.response });
  },

  clearResetPin(mobileNumber){
    return axios.get(url + '/opay-api/user/admin/clear-reset-pin-customercare/'+ mobileNumber).then(response =>response.data)
    .catch((error) => { return error.response });
  },

  checkPasswordExpiry(params) {
    return axios.post(url + '/aacore/user/portal/get-password-expiry', params, {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin' : '*'
        }
    }).then(response => response).catch((error) => { return error.response });
  },

  updateExpiryPassword(params) {
    return axios.post(url + '/aacore/user/portal/change-password', params, {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin' : '*'
        }
    }).then(response => response).catch((error) => { return error.response });
  },

  checkForgotPin(mobile) {
    return axios.get(url + '/opay-api/user/admin/check-reset-pin-customercare/'+mobile, {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin' : '*'
        }
    }).then(response => response).catch((error) => { return error.response });
  },

  getReferral(params){
    return axios.get(url + '/opay-api/promo/referral/list/'+params.id).then(response =>response.data.payload)
      .catch((error) => { return error.response });
  },

  earMarkSave(params){
    return axios.post(url + '/opay-api/earmarking/save', params, {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin' : '*'
        }
    }).then(response =>response.data)
    .catch((error) => { return error.response.data });
  },

  earMarkUpdate(params){
    return axios.post(url + '/opay-api/earmarking/update', params, {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin' : '*'
        }
    }).then(response =>response.data)
    .catch((error) => { return error.response.data });
  },

  earMarkDelete(id){
    return axios.get(url + '/opay-api/earmarking/remove-record/'+id, {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin' : '*'
        }
    }).then(response =>response.data)
    .catch((error) => { return error.response.data });
  },

  earMarkByWalletId(walletId){
    return axios.get(url + '/opay-api/earmarking/get-all/' + walletId, {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin' : '*'
        }
    }).then(response =>response.data)
    .catch((error) => { return error.response.data });
  },

  earMarkByRecordId(id){
    return axios.get(url + '/opay-api/earmarking/find-record/' + id, {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin' : '*'
        }
    }).then(response =>response.data)
    .catch((error) => { return error.response.data });
  },

  earMarkUtilizationById(id){
    return axios.get(url + '/opay-api/earmarking/find-utilization/' + id, {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin' : '*'
        }
    }).then(response =>response.data)
    .catch((error) => { return error.response.data });
  },

  getEarMarkTopUpConfig(){
    return axios.get(url + '/opay-api/earmarking/get-earmark-topup-config', {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin' : '*'
      }
    }).then(response =>response.data)
        .catch((error) => { return error.response.data });
  },

  saveEarMarkTopUpConfig(params){
    return axios.post(url + '/opay-api/earmarking/save-earmark-topup-config', params,{
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin' : '*'
      }
    }).then(response =>response.data)
        .catch((error) => { return error.response.data });
  },

  getReportTRS(params){
    return axios.post(url + '/opay-api/trs/get-report/', params,  {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin' : '*'
        }
    }).then(response =>response.data)
    .catch((error) => { return error.response.data });
  },

  getFileCSV(csv_url){
    return axios.get(csv_url, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Request-Headers': '*',
            'Access-Control-Request-Method': 'GET, OPTIONS, POST, DELETE, PUT',
            'Access-Control-Allow-Credentials': true
        }
    }).then(response =>response)
    .catch((error) => { return error.response });
  },

  getDTOCountry(){
    return axios.get(url + '/opay-api/dtone/get-countries/',  {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin' : '*'
        }
    }).then(response =>response.data)
    .catch((error) => { return error.response.data });
  },

  getDTOOperatorByCountry(country){
    return axios.get(url + '/opay-api/dtone/get-operator-list/'+country,  {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin' : '*'
        }
    }).then(response =>response.data)
    .catch((error) => { return error.response.data });
  },

  getResourceDTOByKey(key){
    return axios.get(url + '/opay-api/dtone/resource-download/'+key,  {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin' : '*'
        }
    }).then(response =>response.data)
    .catch((error) => { return error.response.data });
  },

  dtoUploadImage(params){
    return axios.post(url + '/opay-api/dtone/operator-image-upload', params,  {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Access-Control-Allow-Origin' : '*'
        }
    }).then(response =>response.data)
    .catch((error) => { return error.response.data });
  },

  geEarmarkByUser(params){
    return axios.post(url + '/opay-api/dtone/operator-image-upload', params,  {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Access-Control-Allow-Origin' : '*'
        }
    }).then(response =>response.data)
    .catch((error) => { return error.response.data });
  },

  getTopUpDetails(params){
    return axios.post(url + '/opay-api/topup/get-topup-code-details', params,  {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin' : '*'
      }
    }).then(response => response.data)
        .catch((error) => { return error.response.data });
  },

};
