import axios from 'axios';

const url = process.env.VUE_APP_ROOT_API ; 
const cusCareUrl  = 'http://localhost:6601';
const opayCoreUrl = 'http://localhost:6502';

export default {

  RollbackDbsPayNow(params){  
    return axios.post(url + '/customer-care-portal/transaction/rollback/dbs/paynow',params).then(response =>response.data)
      .catch((error) => { return error.response }); 
  },

  P2PRollback(params){  
    return axios.post(url + '/customer-care-portal/transaction/rollback/p2p',params).then(response =>response.data)
      .catch((error) => { return error.response }); 
  },

  CompleteP2PpPayNow(params){  
    return axios.post(url + '/customer-care-portal/transaction/complete/dbs/paynow/'+ params.movementRef).then(response =>response.data)
      .catch((error) => { return error.response }); 
  },

  getTrFeeList(status){
    return axios.get(url + '/opay-api/transaction-fee/list/'+ status).then(response =>response.data.payload)
      .catch((error) => { return error.response }); 
  },

  saveTrFee(params){  
    return axios.post(url + '/opay-api/transaction-fee/save',params).then(response =>response.data)
      .catch((error) => { return error.response }); 
  },

  findTrFee(feeId){  
    return axios.get(url + '/opay-api/transaction-fee/'+feeId).then(response =>response.data.payload)
      .catch((error) => { return error.response }); 
  },

  updateTrFee(params){  
    return axios.post(url + '/opay-api/transaction-fee/update',params).then(response =>response.data)
      .catch((error) => { return error.response }); 
  }, 
  getActivePrepaidTopup(){  
    return axios.get(url + '/opay-api/topup/prepaid/active/0/1000').then(response =>response.data.payload.content)
      .catch((error) => { return error.response }); 
  },

  getConsumedPrepaidTopup(){  
    return axios.get(url + '/opay-api/topup/prepaid/consumed').then(response =>response.data.payload)
      .catch((error) => { return error.response }); 
  },

  addCards4PrepaidTopup(params){  
    return axios.post(url + '/opay-api/topup/prepaid/add-card',params).then(response =>response.data)
      .catch((error) => { return error.response }); 
  },

  removeCardPrepaidTopup(params){  
    return axios.post(url + '/opay-api/topup/prepaid/remove-card',params).then(response =>response.data) 
      .catch((error) => { return error.response }); 
  },

  getUserGroupList(){  
    return axios.get(url + '/customer-care-portal/user-group/list').then(response =>response.data.payload)
      .catch((error) => { return error.response }); 
  },

  createUserGroup(params){  
    return axios.post(url + '/customer-care-portal/user-group/create',params).then(response =>response.data) 
      .catch((error) => { return error.response }); 
  },

  updateUserGroup(params){  
    return axios.post(url + '/customer-care-portal/user-group/update',params).then(response =>response.data) 
      .catch((error) => { return error.response }); 
  },

  getSingleUserGroup(id){  
    return axios.get(url + '/customer-care-portal/user-group/'+id).then(response =>response.data.payload)
      .catch((error) => { return error.response }); 
  },  

  getRemittaceErrorList(status){ 
    return axios.post(url + '/customer-care-portal/transaction/list/remittance-error-pending/'+status).then(response =>response.data.payload)
      .catch((error) => { return error.response }); 
  },

  completeRemittaceErrorList(ProTxnId){  
    return axios.post(url + '/opay-api/remittance/change-status/complete/'+ ProTxnId).then(response =>response.data)
      .catch((error) => { return error.response }); 
  },  

  // getRemittaceErrorList(status){ //console.log('local params :' + params); 
  //   return axios.post(cusCareUrl + '/transaction/list/remittance-error-pending/'+status).then(response =>response.data.payload)
  //     .catch((error) => { return error.response }); 
  // },  

  // completeRemittaceErrorList(ProTxnId){  //console.log('local params :' + params); 
  //   return axios.post(opayCoreUrl + '/remittance/change-status/complete/'+ ProTxnId).then(response =>response.data)
  //     .catch((error) => { return error.response }); 
  // },

  cancelRemittaceErrorList(ProTxnId){  
    return axios.post(url + '/opay-api/remittance/change-status/cancel/'+ ProTxnId).then(response =>response.data)
      .catch((error) => { return error.response }); 
  },

  getBankPayNowPending(){  
    return axios.post(url + '/customer-care-portal/transaction/list/dbs-pending').then(response =>response.data.payload)
      .catch((error) => { return error.response }); 
  }, 

  getListSingleFactor(){  
    return axios.get(url + '/opay-api/topup/checkout-fee/get-factor').then(response =>response.data.payload)
      .catch((error) => { return error.response }); 
  },

  CreUpdSingleFactor(params){  
    return axios.post(url + '/opay-api/topup/checkout-fee/add-factor',params).then(response =>response.data)
      .catch((error) => { return error.response }); 
  }, 

  delSingleFactor(id){  
    return axios.get(url + '/opay-api/topup/checkout-fee/remove-factor/'+ id).then(response =>response.data)
      .catch((error) => { return error.response }); 
  },

  getListCombFactor(){  
    return axios.get(url + '/opay-api/topup/checkout-fee/get-combination').then(response =>response.data.payload)
      .catch((error) => { return error.response }); 
  },

  CreteCombFactor(params){  
    return axios.post(url + '/opay-api/topup/checkout-fee/combination/save',params).then(response =>response.data)
      .catch((error) => { return error.response }); 
  }, 
  
  getFeeCombById(id){  
    return axios.get(url + '/opay-api/topup/checkout-fee/get-combination/'+ id ).then(response =>response.data.payload)
      .catch((error) => { return error.response }); 
  },

  UpdateCombFactor(id,params){  
    return axios.post(url + '/opay-api/topup/checkout-fee/combination/update/'+id,params).then(response =>response.data)
      .catch((error) => { return error.response }); 
  }, 

  delCombFactor(id){  
    return axios.get(url + '/opay-api/topup/checkout-fee/remove-combination/'+ id).then(response =>response.data)
      .catch((error) => { return error.response }); 
  },

  getListGreetingCard(){  
    return axios.get(url + '/opay-api/greeting-card/all').then(response =>response.data.payload)
      .catch((error) => { return error.response }); 
  },

  CreateGCard(params){  
    return axios.post(url + '/opay-api/greeting-card/create',params).then(response =>response.data)
      .catch((error) => { return error.response }); 
  }, 

  GetGCardByID(id){  
    return axios.get(url + '/opay-api/greeting-card/get/'+ id ).then(response =>response.data.payload)
      .catch((error) => { return error.response }); 
  },

  UpdateGCard(id,params){  
    return axios.post(url + '/opay-api/greeting-card/update/'+id,params).then(response =>response.data)
      .catch((error) => { return error.response }); 
  }, 

  delGCard(id){  
    return axios.get(url + '/opay-api/greeting-card/remove/'+ id).then(response =>response.data)
      .catch((error) => { return error.response }); 
  }, 

  uploadImageGCard(id,formData){
    return axios.post(url + '/opay-api/greeting-card/resource-upload/'+id, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }      
    }).then(response =>response.data)
    .catch((error) => { return error.response }); 
  },

  downloadImageGCard(key){
    return axios.get(url + '/opay-api/greeting-card/resource-download/'+key,{responseType: 'arraybuffer'}).then(response =>Buffer.from(response.data, 'binary').toString('base64'))
    .catch((error) => { return error.response }); 
  },

  getListBGImage(){  
    return axios.get(url + '/opay-api/background-image/all').then(response =>response.data.payload)
      .catch((error) => { return error.response }); 
  },

  CreateBGImage(params){  
    return axios.post(url + '/opay-api/background-image/create',params).then(response =>response.data)
      .catch((error) => { return error.response }); 
  }, 

  uploadBGImage(id,formData){
    return axios.post(url + '/opay-api/background-image/resource-upload/'+id, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }      
    }).then(response =>response.data)
    .catch((error) => { return error.response }); 
  },

  downloadBGImage(key){
    return axios.get(url + '/opay-api/background-image/resource-download/'+key,{responseType: 'arraybuffer'}).then(response =>Buffer.from(response.data, 'binary').toString('base64'))
    .catch((error) => { return error.response }); 
  },

  GetbgImageByID(id){ 
    return axios.get(url + '/opay-api/background-image/get/'+ id ).then(response =>response.data.payload)
      .catch((error) => { return error.response }); 
  },

  UpdateBGImage(id,params){  
    return axios.post(url + '/opay-api/background-image/update/'+id,params).then(response =>response.data)
      .catch((error) => { return error.response }); 
  }, 

  delBGImage(id){  
    return axios.get(url + '/opay-api/background-image/remove/'+ id).then(response =>response.data)
      .catch((error) => { return error.response }); 
  }, 

  getPTCPendingList(){  
    return axios.get(url + '/opay-api/ptc/pending/list').then(response =>response.data.payload)
      .catch((error) => { return error.response }); 
  },

  cancelPTCPendingList(TrRefId){  
    return axios.post(url + '/opay-api/ptc/change-status/cancel/'+ TrRefId).then(response =>response.data)
      .catch((error) => { return error.response }); 
  },

  completePTCPendingList(TrRefId){  
    return axios.post(url + '/opay-api/ptc/change-status/complete/'+ TrRefId).then(response =>response.data)
      .catch((error) => { return error.response }); 
  },  

  getQRISPendingList(){ 
    return axios.get(url + '/opay-api/qris/pending/list').then(response =>response.data.payload)
      .catch((error) => { return error.response }); 
  },

  cancelQrisPendingList(params){ 
    return axios.post(url + '/opay-api/qris/rollback',params).then(response =>response.data)
      .catch((error) => { return error.response }); 
  },

  completeQrisPendingList(params){ 
    return axios.post(url + '/opay-api/qris/complete',params).then(response =>response.data)
      .catch((error) => { return error.response }); 
  }, 

  getWeChatPendingList(){ 
    return axios.get(url + '/opay-api/trs/pending/list').then(response =>response.data.payload)
      .catch((error) => { return error.response }); 
  },

  cancelWeChatPendingList(params){ 
    return axios.post(url + '/opay-api/trs/rollback',params).then(response =>response.data)
      .catch((error) => { return error.response }); 
  },

  completeWeChatPendingList(params){ 
    return axios.post(url + '/opay-api/trs/complete',params).then(response =>response.data)
      .catch((error) => { return error.response }); 
  }, 

  getRequestResponseRecord(){  
    return axios.get(url + '/customer-care-portal/transaction/get-request-response-record').then(response =>response.data.payload)
      .catch((error) => { return error.response }); 
  },

  findFeeByType(params){ 
    return axios.post(url + '/opay-api/transaction-fee/find-by-type',params).then(response =>response.data)
    .catch((error) => { return error.response });
  },
  
  getMgAccountBalance(){ 
    return axios.get(url + '/opay-api/trs/account-balance').then(response =>response.data.payload)
      .catch((error) => { return error.response }); 
  },

  getFxRates(params){ 
    return axios.post(url + '/opay-api/trs/get-fx-rate', params).then(response =>response.data.payload)
      .catch((error) => { return error.response }); 
  },

  getTradeFx(){
    return axios.get(url + '/opay-api/trs/list/tradefx').then(response =>response.data.payload)
      .catch((error) => { return error.response }); 
  },

  addTradeFx(params){
    return axios.post(url + '/opay-api/trs/trade-fx', params).then(response =>response.data)
      .catch((error) => { return error.response }); 
  },

  rejectTradeFx(id){
    return axios.get(url + '/opay-api/trs/reject-tradeFx/'+ id).then(response =>response.data)
      .catch((error) => { return error.response }); 
  },

  approveTradeFx(id){
    return axios.get(url + '/opay-api/trs/approve-tradeFx/'+ id).then(response =>response.data)
      .catch((error) => { return error.response }); 
  },

  getStatusTradeFx(tradeNo){
    return axios.get(url + '/opay-api/trs/query-tradefx-status/'+ tradeNo).then(response =>response.data.payload)
      .catch((error) => { return error.response }); 
  },

  downloadRefImage(key){
    return axios.get(url + '/opay-api/common/n-auth/resource-download/'+key,{responseType: 'arraybuffer'}).then(response =>Buffer.from(response.data, 'binary').toString('base64'))
    .catch((error) => { return error.response });
  },

  uploadRefImage(formData){
    return axios.post(url + '/opay-api/common/resource-upload', formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }).then(response =>response.data)
    .catch((error) => { return error.response });
  },

  downloadDbsMcaBanner(){
    return axios.get(url + '/opay-api/dbs-mca/resource-download',{responseType: 'arraybuffer'}).then(response =>Buffer.from(response.data, 'binary').toString('base64'))
    .catch((error) => { return error.response });
  },

  uploadDbsMcaBanner(formData){
    return axios.post(url + '/opay-api/dbs-mca/resource-upload', formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }).then(response =>response.data)
    .catch((error) => { return error.response });
  },

  systemTransactionFindRequest() {
    return axios.get(url + '/opay-api/system-transaction-request/find-all', {
      headers: {
        "Content-Type": "application/json"
      }
    }).then(response => response.data)
        .catch((error) => { return error.response });
  },

  systemTransactionCreate(params) {
    return axios.post(url + '/opay-api/system-transaction-request/create', params, {
      headers: {
        "Content-Type": "application/json"
      }
    }).then(response => response.data)
    .catch((error) => { return error.response });
  },

  systemTransactionFindAll() {
    return axios.get(url + '/opay-api/system-transaction-request/find-pending', {
      headers: {
        "Content-Type": "application/json"
      }
    }).then(response => response.data)
    .catch((error) => { return error.response });
  },

  systemTransactionFindHistory() {
    return axios.get(url + '/opay-api/system-transaction-request/history', {
      headers: {
        "Content-Type": "application/json"
      }
    }).then(response => response.data)
    .catch((error) => { return error.response });
  },

  systemTransactionApprove(id) {
    return axios.get(url + '/opay-api/system-transaction-request/approve/'+id, {
      headers: {
        "Content-Type": "application/json"
      }
    }).then(response => response.data)
    .catch((error) => { return error.response });
  },

  systemTransactionReject(id) {
    return axios.get(url + '/opay-api/system-transaction-request/reject/'+id, {
      headers: {
        "Content-Type": "application/json"
      }
    }).then(response => response.data)
    .catch((error) => { return error.response });
  },

  getMatchingBankReference(url_mg, xapikey, params) {
    return axios.post(url_mg + 'payment-register/find', params, {
      crossdomain: true,
      headers: {
        "Content-Type": "application/json",
        "x-api-key": xapikey
      }
    }).then(response => response.data)
        .catch((error) => { return error });
  },

  setMatchingBankReference(url_mg, xapikey, params) {
    return axios.post(url_mg + 'payment-register/update', params, {
      crossdomain: true,
      headers: {
        "Content-Type": "application/json",
        "x-api-key": xapikey
      }
    }).then(response => response.data)
        .catch((error) => { return error });
  },
};
