<template>
<div>
	<CRow>
		<CCol sm="6" md="8">
        <CCard>
          <CCardHeader>
            <h4>Add Card</h4>      
          </CCardHeader>
          <CCardBody> 

            <CRow>
              <CCol col="12" class="text-left">                   
                <CAlert v-bind:color="color" v-if="seen" id="alert">{{ msg }}</CAlert>
              </CCol> 
            </CRow> 

              <CInput
                type="number"  
                min=0
                max=1000  
                description="Max 100 Cards per Add"            
                label="Number Of Cards"
                v-model="numberOfCards"                
                horizontal                
              />        

              <CInput
                type="text"                
                label="Card Prefix"
                v-model="cardPrefix"                
                horizontal                
              />

             
              <CInput
                label="Expired Date"
                type="date"                
                v-model="expiryDate"                
                horizontal                
              />            
              

               <b-row>
                  <b-col sm="3">                    
                     <label for="input-small" >Currency</label>
                  </b-col>   
                  <b-col sm="9"> 
                     <b-form-select v-model="currency" :options="optCurrency"></b-form-select>
                   </b-col>                  
               </b-row><br/>


              <CInput
                type="number"
                min=0  step="any"
                label="Amount"
                v-model="amount"                
                horizontal                
              />

              <b-row>
                  <b-col sm="3"></b-col>   
                  <b-col sm="9">
                    <b-button-group>
                    <router-link to="/prepaidtopup/active" style="width:100px;" tag="b-button">Cancel</router-link>
                    <b-button style="width:100px;" v-on:click="actionSubmit()" variant="primary">Submit</b-button>
                    </b-button-group>
                  </b-col>                  
              </b-row><br/>        
                

          </CCardBody>
        </CCard>
      </CCol>

     </CRow>

</div>
</template>


<script>
 import AuthService from '@/api/AuthService.js';
 import TransactService from '@/api/TransactService.js'; 
 

  export default {     
    data() {
      return { 
          cardPrefix:'',
          numberOfCards:'',
          expiryDate:'',
          currency:'SGD',
          amount:0,
          optCurrency:[],
          seen:false,
          msg :'',
          color:''          
        } ;      
      
    },
    created(){  
       this.getConfigList();      
    },

    methods: {     

      actionSubmit(){

          let params = {}; var validate = [];   var msg = '';       
          params['numberOfCards'] = this.numberOfCards;
          params['cardPrefix'] = this.cardPrefix;
          params['currency'] = this.currency;
          params['expiryDate'] = this.expiryDate;        
          params['amount'] = parseFloat(this.amount);

          for (var key in params) {
            if(!params[key]) { validate.push(false); } else { validate.push(true); }
          } 

          if(params['numberOfCards'] > 100 ){
              validate.push(false); 
              msg  = 'Failed, Number of Card not more than 100!'; 
          }  

          if(!validate.includes(false)){  //console.log(params);
            this.addCards4PrepaidTopup(params);           
          } else {           
            this.msg   = !msg ? 'Data Not Complete, All fields are Required !' : msg; 
            this.color = 'warning'; this.seen  = true;
            setTimeout( () =>  this.seen=false , 5000);
          } 

      },

      actionReset(){

          this.numberOfCards = '';
          this.cardPrefix ='',
          this.currency = 'SGD';
          this.expiryDate='';
          this.amount='';

      },

      addCards4PrepaidTopup: function(params) { 
           TransactService.addCards4PrepaidTopup(params).then(resp => { //console.log(resp); 

              if(resp.message=='Success'){
                this.msg   = 'Add Card Successfully '; 
                this.color = 'success';  this.seen  = true;
                this.actionReset(); 
              } else {
                this.msg   = resp.data.message; 
                this.color = 'warning'; this.seen  = true;                
              }

              setTimeout( () =>  this.seen=false , 5000);

           }, error => {
            console.log('error');    
          });
      },  

      getConfigList: function() { 
          AuthService.getConfigList().then(resp => {
            
            var currSrc=[]; var temp = []; var temp2 = [];
            var arrcurrency = resp.currency;
            for (const item of arrcurrency){ temp.push(item); }
            temp.sort(); 
            for (var key in temp) {
                var curr = temp[key];  
                currSrc.push({ value: curr , text: curr });
            }
            this.optCurrency = currSrc;

           }, error => {
            console.log('error');    
          });
      },     
      
    }
  }
</script>